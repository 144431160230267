import { useEffect } from "react";
import { getAppSDK } from "../helpers";
export var useCheckoutReady = function(isLoading) {
    useEffect(function() {
        var appSDK = getAppSDK();
        if (!isLoading) {
            appSDK === null || appSDK === void 0 ? void 0 : appSDK.sendMessageToAppSDK("checkout_ready");
        }
    }, [
        isLoading
    ]);
};
