import queryString from "query-string";
import { getAppSDK } from "./mobileApp";
export var PAYMENT_DEEP_LINK = "checkout/payment/paymentredirect";
export var getPaymentsRedirectDeepLink = function(param) {
    var webUrl = param.webUrl, stage = param.stage, prefix = param.prefix;
    var nextQueryStr = queryString.stringify({
        webUrl: webUrl,
        stage: stage,
        prefix: prefix
    });
    var appSDK = getAppSDK();
    var featureFlags = (appSDK === null || appSDK === void 0 ? void 0 : appSDK.getTrackingProps().app_feature_flags) || [];
    if (!featureFlags.includes("withPaypalWebView")) {
        return webUrl;
    }
    return "".concat(window.origin, "/mobile/").concat(PAYMENT_DEEP_LINK, "?").concat(nextQueryStr);
};
