import { config } from "@finn/ui-utils";
var ENVS;
(function(ENVS) {
    ENVS["DEV"] = "dev";
    ENVS["PREVIEW"] = "preview";
})(ENVS || (ENVS = {}));
export var getDeepLink = function(param) {
    var link = param.link, stage = param.stage, prefix = param.prefix;
    if (stage === ENVS.DEV) {
        return "".concat(prefix).concat(link);
    } else if (stage === ENVS.PREVIEW) {
        return "".concat(config.APP_PREVIEW_PREFIX).concat(link);
    }
    return "".concat(config.APP_PREFIX).concat(link);
};
