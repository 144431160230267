import { useEffect } from "react";
import { getAppSDK } from "../helpers";
export var useWebViewNavigationEnded = function(loading, setLoading) {
    useEffect(function() {
        var appSDK = getAppSDK();
        var stopCTALoading = function() {
            if (loading) {
                setLoading(false);
            }
        };
        if (loading) {
            var unsubscribe = appSDK === null || appSDK === void 0 ? void 0 : appSDK.on("navigation_ended", stopCTALoading);
            return function() {
                unsubscribe === null || unsubscribe === void 0 ? void 0 : unsubscribe();
            };
        }
        return function() {
        // empty
        };
    }, [
        loading,
        setLoading
    ]);
};
